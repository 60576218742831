import React, { useRef } from 'react';
import {useState} from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'


const FormEstimate = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [showError, setshowError] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    const { 
      Service,
      ContactPerson,
      ContactEmail,
      ContactPhone,
      Zip,
      Location,
      emergency,
      Homeowner,
      Tell } = e.target.elements
      
    let conFom = {
      Service: Service.value,
      ContactPerson: ContactPerson.value,
      ContactEmail: ContactEmail.value,
      ContactPhone: ContactPhone.value,
      Zip: Zip.value,
      Location: Location.value,
      emergency: emergency.value,
      Homeowner: Homeowner.value,
      Tell: Tell.value,
    }
    console.log(conFom);

    var dataPost = new FormData();
    dataPost.append("Service", conFom.Service);
    dataPost.append("ContactPerson", conFom.ContactPerson); 
    dataPost.append("ContactEmail", conFom.ContactEmail);
    dataPost.append("ContactPhone", conFom.ContactPhone); 
    dataPost.append("Zip", conFom.Zip);
    dataPost.append("Location", conFom.Location); 
    dataPost.append("emergency", conFom.emergency); 
    dataPost.append("Homeowner", conFom.Homeowner); 
    dataPost.append("Tell", conFom.Tell);
    
   //var url ='http://localhost/emails.php';
   var url ='/emails.php';

    fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: dataPost
    })
       .then((response) => response.json())
       .then((data) => {
          console.log(data);
          console.log("SUCCESS!", data);   
          setShowMessage(true);  
          Swal.fire({
            title:'Roofing Specialist will be contacting you shortly!!',
            position: 'top',
            icon: 'success',
            showConfirmButton: true,
            timer: 6500
          })

       })
       .catch((err) => {
          setshowError(true);
          Swal.fire({
            title:'Form could not be sent, please try again!!!!',
            position: 'top',
            icon: 'error',
            showConfirmButton: true,
            timer: 6500
          })
       });
    


   /*   emailjs.sendForm("service_zkjn8wa", "template_7iimknn", form.current, 'user_xwcTrHoVzpUAaqbmV2dYe')
      .then((result) => {     
        console.log("SUCCESS!", result.status, result.text);   
        setShowMessage(true);

        Swal.fire({
          title:'Roofing Specialist will be contacting you shortly!!',
          position: 'top',
          icon: 'success',
          showConfirmButton: true,
          timer: 6500
        })

      }, (error) => {
          console.log(error.text);
          setshowError(true);
          Swal.fire({
            title:'Form could not be sent, please try again!!!!',
            position: 'top',
            icon: 'error',
            showConfirmButton: true,
            timer: 6500
          })
      });*/
  };


  return (
    <>
<div className="FormContact" id="Free">
  <div className="container">

 

  <form ref={form} onSubmit={sendEmail}>
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <h1>Get a Free No-Obligation Estimate Now!</h1>
        { showMessage ? <div className="alert alert-success col-md-12 mx-auto" role="alert">Roofing Specialist will be contacting you shortly!!</div> : ''}

        { showError ? <div className="alert alert-warning col-md-12 mx-auto" role="alert">Form could not be sent, please try again!!</div> : ''}

      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="form-group">
          <label for="exampleFormControlSelect1">Service</label>
          <select class="form-control" name="Service" id="Service" required> 
            <option value=""> Select an option </option>
            <option value="Roof Repair">Roof Repair</option> 
            <option value="Roof Replacement">Roof Replacement</option> 
            <option value="Roof inspections">Roof inspections</option> 
            <option value="Roof tune-up">Roof tune-up</option> 
          </select>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="form-group">
          <label for="exampleFormControlSelect1">Contact Person</label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            id="ContactPerson"
            name="ContactPerson"
            required
           />
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="form-group">
          <label for="exampleFormControlSelect1">Contact Email</label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            id="ContactEmail"
            name="ContactEmail"
            required
                  />
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="form-group">
          <label for="exampleFormControlSelect1">Contact Phone</label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            id="ContactPhone"
            name="ContactPhone"
            required
          />
        </div>
      </div>

      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="form-group">
          <label for="exampleFormControlSelect1">
            Postal Code / Zip Code
          </label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            id="Zip"
            name="Zip"
          />
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6">
        <div className="form-group">
          <label for="exampleFormControlSelect1">Location</label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            id="Location"
            name="Location"
            
          />
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="form-group">
          <label for="exampleFormControlSelect1">
            Is it a emergency?
          </label>
          <select
            className="form-control"
            id="emergency"
            name="emergency"
          
          >
            <option>It's Not An Emergency.</option>
            <option>I Have An Emergency!</option>
          </select>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="form-group">
          <label for="exampleFormControlSelect1">
            Are you Homeowner?
          </label>
          <select
            className="form-control"
            id="Homeowner"
            name="Homeowner"
        
          >
            <option>I'm The Homeowner</option>
            <option>I'm The Desicion Maker</option>
            <option>I'm Not The Homeowner</option>
          </select>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div class="form-group">
          <label for="exampleFormControlTextarea1">
            Tell us about your project
          </label>
          <textarea
            class="form-control"
            id="Tell"
            rows="3"
            name="Tell"
           
          ></textarea>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <input type="submit" class="btn btn-primary" value="Send" />       
      </div>
    </div>
  </form>
</div>
</div>
    </>
  );
};

export default FormEstimate;
