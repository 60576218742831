const Banner = () => {
  return (
    <>
      <section className="Banner">
          <div className="container">
              <div className="row">
                  <div className="col-md-6">

                  </div>
                  <div className="col-md-6 cont">
                  <div className="text">
                        Are you interested <br />in our service? <br /> <span> Get a free estimate:</span>
                        <a href="tel:+15615447299"><h1>+1 561 544 7299</h1></a>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default Banner;
