import logowh from'../img/aRoofing-5big.png';

const Aboust = () => {
  return (
    <>
      <section className="Aboust">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src={logowh} alt="" className="src" />
                  <p className="Aboust-text">
                  Expert in the area of repair and replacement of roofs for year we have strive to give you a services of execellent quality at reasonable cost, always worrying about filfilling the proyect of assumes.   
                        </p>
                    </div>
                </div>
            </div>
      </section>
      
    </>
  )
}

export default Aboust
