import React from "react";
import img from "../img/r2_c1.png";

const Contact = (props) => {
  return (
    <>
      <section className="Contact">
     <div className="fill"> 
     <div className="container Contact-Phone">
          <div className="row">
            <div className="col-12-m text-center">
              <h2 className="">Are you insteresd ?</h2>
              <h3>Call us for a totally free and no obligation estimante</h3>
              <a href="tel:+15615447299">
                <h1 className="phone">+1 561 544 7299</h1>
              </a>
            </div>
          </div>
        </div>

     </div>
     

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-justify">
         
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d68023.60414905139!2d-80.14902231875652!3d26.35723487598671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d8e1f646b57c9b%3A0x15a3b73b49f5dfb!2s980%20N%20Federal%20Hwy%20%23110%2C%20Boca%20Raton%2C%20FL%2033432%2C%20EE.%20UU.!5e0!3m2!1ses!2sve!4v1637075853003!5m2!1ses!2sve" width="100%" height="450"  allowfullscreen="" loading="lazy"></iframe>
            </div>            
          </div>
        </div>

     
      </section>
    </>
  );
};

export default Contact;
