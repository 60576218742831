import React from "react";
import Logo from "../img/aRoofing-5.png";
const Navbar = () => {
  return (
    <>
      <section className="Header">
        <div className="container">
          <header className=" d-flex flex-wrap justify-content-center py-3 border-bottom">
            <a
              href="/"
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
            >
              <img src={Logo} alt="" className="" />
            </a>

            <ul class="nav nav-pills">
              <li className="nav-item">
                <a
                  href="#home"
                  className="nav-link text-black"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="#Services" className="nav-link text-black">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a href="#Services" className="nav-link text-black">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a href="#Contact" className="nav-link text-black">
                  Contact
                </a>
              </li>
            </ul>
          </header>
        </div>
      </section>
    </>
  );
};

export default Navbar;
